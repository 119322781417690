<template>
  <div id="shipments">
    <div class="row">
      <not-found-info :item="displayedFullAdverts" :title="'Sevkiyat'" :emptyName="'deliverys'" />
      <shipment-card v-for="(item, index) in displayedFullAdverts" :key="index" :details="item" />
      <div class="col-md-12 d-flex justify-content-center mb-5" v-if="pages.length > 1">
        <nav aria-label="Page navigation example">
          <ul class="pagination">
            <li class="page-item">
              <button class="page-link" type="button" aria-label="Previous" @click="page--">
                <span aria-hidden="true">&laquo;</span>
                <span class="sr-only">Previous</span>
              </button>
            </li>
            <li v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)" :key="index" @click="page = pageNumber" class="page-item" :class="{ active: pageNumber == page }">
              <button type="button" class="page-link text-white active">
                {{ pageNumber }}
              </button>
            </li>
            <li class="page-item">
              <a class="page-link" href="#" aria-label="Next" @click="page++">
                <span aria-hidden="true">&raquo;</span>
                <span class="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import NotFoundInfo from "../../components/notFound/notFoundInfo.vue";
import shipmentCard from "../../components/shipmentCard/shipmentCard";
import META_DATA from "../../constants/metaData";

export default {
  data() {
    return {
      page: 1,
      perPage: 10,
      pages: [],
    };
  },
  metaInfo() {
    return {
      title: META_DATA.SHIPMENTS.Title,
    };
  },
  components: {
    shipmentCard,
    NotFoundInfo,
  },
  async created() {
    if (this.$store.state.User.token) {
      this.$store.dispatch("getTransferAdvertsShipment", {});
      this.$store.dispatch("getCompletedAdvertsShipment", {});
    }

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params.success === "true") window.sendNotification.success("Ödemeniz başarı ile gerçekleştirildi!");
    else if (params.success === "false") window.sendNotification.error(params.message ? params.message : "Ödemeniz gerçekleştirilemedi!");
    console.log(this.displayedFullAdverts)
  },
  computed: {
    displayedFullAdverts() {
      return this.paginate(this.fullAdverts);
    },
    fullAdverts: {
      get() {
        return this.shipments.concat(this.completedAdverts);
      },
    },
    shipments: {
      get() {
        return this.$store.getters["shipments"];
      },
    },
    completedAdverts: {
      get() {
        return this.$store.getters["completedAdverts"];
      },
    },
  },
  methods: {
    setPages() {
      let numberOfPages = Math.ceil(this.fullAdverts?.length / this.perPage);
      this.pages = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(fullAdverts) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return fullAdverts?.slice(from, to);
    },
  },
  watch: {
    fullAdverts() {
      this.setPages();
    },
    page() {
      if (this.page == 0) {
        this.page = this.pages.length;
      } else if (this.page > this.pages.length) {
        this.page = 1;
      }
    },
  },
};
</script>

<style scoped>
#shiptment span {
  color: #fff;
  font-size: 14px;
}
#shipments {
  padding: 15px;
}
</style>
